import './services.scss'

import React, { Component } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SimpleBar from 'simplebar-react'
import { graphql } from 'gatsby'

// import bgvideoWEBM from '../assets/videos/services.webm'

class ServicesTemplate extends Component {

  render() {
    const page = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title={page.yoast_meta.title} description={page.yoast_meta.desc} />
        <section className="page-left page-services--left">
          <div className="page-left--wrapper">
            <SimpleBar style={{ maxHeight: '100%', paddingRight: '80px' }}>
              <div className="page-left--content page-services--content">
                <p className="p-strong" style={{marginBottom: 0}}><strong>INTERNATIONAL CLIENTS CHOOSE THE WARSAW PRODUCTION TEAM</strong></p>
                <p className="p-normal" style={{fontFamily: 'Caligo', textTransform: 'uppercase'}}>as their local partner for superior production services at competitive prices.</p>
                <p className="p-normal">We tap into Poland’s rich film-making history of brilliant talent, experienced production professionals, amazing locations, and state-of-the-art film studios to make it the ideal location for your next project. </p>
                <p className="p-normal">Join our growing list of international clients who look to us for exceptional and competitively priced production services.</p>
                <p className="p-normal">Work with us in Poland and get killer production value for your budget.</p>
                <h2>PRICING EXAMPLES:</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>CAMERA ASSISTANT</td>
                      <td>300€</td>
                      <td>DAY</td>
                    </tr>
                    <tr>
                      <td>GAFFER</td>
                      <td>280€</td>
                      <td>DAY</td>
                    </tr>
                    <tr>
                      <td>FILM STUDIO 150 SQ. M</td>
                      <td>480€</td>
                      <td>DAY</td>
                    </tr>
                    <tr>
                      <td>FILM STUDIO 600 SQ. M</td>
                      <td>700€</td>
                      <td>DAY</td>
                    </tr>
                    <tr>
                      <td>4**** HOTEL</td>
                      <td>70–180€</td>
                      <td>DAY</td>
                    </tr>
                    <tr>
                      <td>TAXI</td>
                      <td>0,5€</td>
                      <td>KM</td>
                    </tr>
                    <tr>
                      <td>GOURMET DOUBLE ESPRESSO</td>
                      <td>1,6€</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>BIG LUNCH</td>
                      <td>7€</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>REGIONAL BEER AT PUB</td>
                      <td>2,5€.</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-services--two-col">
                  <div>
                    <h3>WORKDAY:</h3>
                    <p>
                      11 hours / 1 hour break<br />
                      overtime possible
                    </p>
                  </div>
                  <div>
                    <h3>WARSAW CHOPIN AIRPORT:</h3>
                    <p>
                      108 routes to 3 continents<br />
                      45 countries directed to Warsaw
                    </p>
                  </div>
                </div>
                <h3>AIRLINES:</h3>
                <p>
                  British Airways, AirFrance, Lufthansa,<br />
                  KLM, Norwegian, Quatar, Finnair, Swiss,<br />
                  SAS, Emirates, TAP, Alitalia<br />
                  and many more.
                </p>
              </div>
            </SimpleBar>
          </div>
        </section>
        <section className="page-right page-services-right">
        </section>
      </Layout>
    )
  }
}

export default ServicesTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      content
      yoast_meta {
        title
        desc
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
